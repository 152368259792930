import React from 'react';

interface IconProps {
  width: number;
  height: number;
}

const icon: React.FC<IconProps> = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 63 61"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.22879 46.0837C1.57879 47.1437 0.848797 48.3737 0.278797 49.3537C0.0120292 49.8045 -0.0672331 50.342 0.0580516 50.8506C0.183336 51.3592 0.503165 51.7984 0.948795 52.0737L13.9488 60.0737C14.1747 60.2132 14.4261 60.3063 14.6884 60.3476C14.9506 60.3889 15.2185 60.3776 15.4763 60.3143C15.7341 60.251 15.9768 60.137 16.1901 59.9789C16.4034 59.8209 16.5832 59.6219 16.7188 59.3937C17.2288 58.5237 17.8988 57.3937 18.6288 56.1837C23.7788 47.6837 28.9688 48.7237 38.2988 53.1837L51.1888 59.3137C51.4304 59.4287 51.6927 59.4941 51.96 59.5059C52.2274 59.5177 52.4944 59.4757 52.7452 59.3825C52.9961 59.2892 53.2257 59.1466 53.4204 58.963C53.6151 58.7794 53.771 58.5586 53.8788 58.3137L60.0688 44.3137C60.279 43.8331 60.292 43.2892 60.1049 42.7991C59.9179 42.309 59.5458 41.912 59.0688 41.6937C56.3488 40.4137 50.9388 37.8537 46.0688 35.5137C28.5088 26.9737 13.6188 27.5337 2.22879 46.0837Z"
        fill="url(#paint0_linear_1_52)"
      />
      <path
        d="M60.5188 14.2837C61.1688 13.2237 61.8988 11.9837 62.5188 11.0137C62.7847 10.5617 62.8624 10.0234 62.7352 9.51463C62.608 9.00588 62.2861 8.56744 61.8388 8.29373L48.8388 0.293729C48.6133 0.155831 48.3627 0.0640062 48.1015 0.0235564C47.8403 -0.0168935 47.5736 -0.0051613 47.317 0.0580718C47.0603 0.121305 46.8187 0.234781 46.6062 0.391949C46.3937 0.549117 46.2144 0.746855 46.0788 0.973729C45.5588 1.84373 44.8888 2.97373 44.1588 4.18373C39.0088 12.6837 33.8288 11.6437 24.4888 7.18373L11.5588 1.08373C11.3177 0.970305 11.0563 0.906216 10.79 0.895222C10.5238 0.884229 10.258 0.926552 10.0083 1.01971C9.75867 1.11287 9.53014 1.25498 9.33616 1.4377C9.14219 1.62042 8.98669 1.84007 8.87879 2.08373L2.68879 16.0837C2.47859 16.5643 2.46561 17.1083 2.65266 17.5984C2.83972 18.0884 3.21182 18.4854 3.68879 18.7037C6.40879 19.9837 11.8288 22.5337 16.6888 24.8837C34.2388 33.3537 49.1288 32.7937 60.5188 14.2837Z"
        fill="url(#paint1_linear_1_52)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1_52"
          x1="59.6788"
          y1="64.1737"
          x2="20.3488"
          y2="41.5637"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0050D3" />
          <stop offset="0.94" stopColor="#007FFC" />
          <stop offset="1" stopColor="#0082FF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1_52"
          x1="3.06879"
          y1="-3.83627"
          x2="42.3988"
          y2="18.7637"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0050D3" />
          <stop offset="0.94" stopColor="#007FFC" />
          <stop offset="1" stopColor="#0082FF" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default icon;
