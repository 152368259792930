import React from 'react';

interface IconProps {
  width: number;
  height: number;
}

const icon: React.FC<IconProps> = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="M10.7086 31.496C10.7086 34.3701 8.38581 36.6929 5.51179 36.6929C2.63778 36.6929 0.314941 34.3701 0.314941 31.496C0.314941 28.622 2.63778 26.2992 5.51179 26.2992H10.7086V31.496ZM13.3071 31.496C13.3071 28.622 15.6299 26.2992 18.5039 26.2992C21.3779 26.2992 23.7008 28.622 23.7008 31.496V44.4882C23.7008 47.3622 21.3779 49.685 18.5039 49.685C15.6299 49.685 13.3071 47.3622 13.3071 44.4882V31.496Z"
          fill="#E01E5A"
        />
        <path
          d="M18.5039 10.6299C15.6299 10.6299 13.3071 8.30707 13.3071 5.43306C13.3071 2.55904 15.6299 0.236206 18.5039 0.236206C21.3779 0.236206 23.7008 2.55904 23.7008 5.43306V10.6299H18.5039ZM18.5039 13.2677C21.3779 13.2677 23.7008 15.5905 23.7008 18.4646C23.7008 21.3386 21.3779 23.6614 18.5039 23.6614H5.47242C2.59841 23.6614 0.275574 21.3386 0.275574 18.4646C0.275574 15.5905 2.59841 13.2677 5.47242 13.2677H18.5039Z"
          fill="#36C5F0"
        />
        <path
          d="M39.3307 18.4646C39.3307 15.5905 41.6535 13.2677 44.5275 13.2677C47.4016 13.2677 49.7244 15.5905 49.7244 18.4646C49.7244 21.3386 47.4016 23.6614 44.5275 23.6614H39.3307V18.4646ZM36.7323 18.4646C36.7323 21.3386 34.4094 23.6614 31.5354 23.6614C28.6614 23.6614 26.3386 21.3386 26.3386 18.4646V5.43306C26.3386 2.55904 28.6614 0.236206 31.5354 0.236206C34.4094 0.236206 36.7323 2.55904 36.7323 5.43306V18.4646Z"
          fill="#2EB67D"
        />
        <path
          d="M31.5354 39.2913C34.4094 39.2913 36.7323 41.6142 36.7323 44.4882C36.7323 47.3622 34.4094 49.685 31.5354 49.685C28.6614 49.685 26.3386 47.3622 26.3386 44.4882V39.2913H31.5354ZM31.5354 36.6929C28.6614 36.6929 26.3386 34.3701 26.3386 31.496C26.3386 28.622 28.6614 26.2992 31.5354 26.2992H44.5669C47.4409 26.2992 49.7638 28.622 49.7638 31.496C49.7638 34.3701 47.4409 36.6929 44.5669 36.6929H31.5354Z"
          fill="#ECB22E"
        />
      </g>
      <defs>
        <clipPath id="clip0_10_567">
          <rect width="50" height="50" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default icon;
